import '@/assets/styles/tailwind.css';
import '@/assets/styles/globals.css';
import { DefaultLayout } from '@/layouts';
import type { AppProps } from 'next/app';
import { ConfigProvider } from 'antd';
import { MenuItem } from '@/utils/menu';
import Router from 'next/router';
import { pageSettingIdEnum } from '@/utils/config';
function renderLabel(menu: MenuItem) {
  const handleClick = () => {
    if (menu.id === pageSettingIdEnum.AboutUs) {
      Router.push('/contact-us');
      return;
    }
    if (menu.id === pageSettingIdEnum.IndexPage) {
      Router.push('/');
      return;
    }
    let seleceMenu = menu;
    if (menu.children && menu.children.length) {
      seleceMenu = menu.children[0];
    }
    const categoryId =
      seleceMenu.categories && seleceMenu.categories.length ? seleceMenu.categories[0].id : '';
    Router.push('/index?id=' + seleceMenu.id + '&categoryId=' + categoryId);
  };
  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {' '}
      {menu.name}{' '}
    </div>
  );
}
export function filterMenuShow(menuList: MenuItem[]) {
  const list: MenuItem[] = [];
  for (const menu of menuList) {
    const children = menu.children ? filterMenuShow(menu.children) : [];
    list.push({
      ...menu,
      children: children.length && menu.id !== pageSettingIdEnum.IndexPage ? children : undefined,
      label: renderLabel(menu),
      key: `${menu.id}`,
    });
  }
  return list.filter((item: MenuItem) => !item.hideInHeader);
}

export default function App({ Component, pageProps }: AppProps) {
  const headerMenuList: MenuItem[] = filterMenuShow(pageProps.baseInfo?.columnInfo || []);
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#5CAB12' } }}>
      <DefaultLayout menuList={headerMenuList} baseInfo={pageProps.baseInfo}>
        <Component {...pageProps} />
      </DefaultLayout>
    </ConfigProvider>
  );
}
