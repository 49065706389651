/* eslint-disable no-unused-vars */
import { ReactNode } from 'react';
import { pageSettingIdEnum } from './config';
import { columnIdType } from './model';

export enum keyEnum {
  Index = 'index',
  Association = 'association',
  Regulations = 'regulations',
  MembersService = 'members-service',
  PartyConstruction = 'party-construction',
  StandardizationWork = 'standardization-work',
  Branch = 'branch',
  ContactUs = 'contact-us',
}

export type CategoriesType = {
  id: string;
  name: string;
};

export type MenuItem = {
  categories?: CategoriesType[];
  id?: columnIdType;
  parentId?: number;
  breadName?: string;
  categoryId?: string;
  template?: string;
  loadSubBar?: boolean;
  subheader?: string;
  title?: string;
  type?: string;
  label?: ReactNode;
  validatePath?: string;
  path?: string;
  name: string;
  articletype?: string;
  articlesubtype?: string[];
  sort?: number;
  key: string;
  hideInHeader?: boolean;
  children?: MenuItem[] | undefined;
};

export enum pathEnum {
  Index = '/', // 首页
  IndexList = `/index`, // 资讯动态
  IndexDetail = `/index/detail`, // 会员风采详情
  ContactUs = '/contact-us', // 联系我们
}

export const allMenuList: MenuItem[] = [
  {
    path: pathEnum.Index,
    name: '首页',
    key: 'index',
    title: '中国轮胎循环利用网',
    children: [
      {
        path: pathEnum.IndexList,
        name: '列表',
        key: `index-list`,
        children: [
          {
            path: pathEnum.IndexDetail,
            name: '详情',
            key: `index-detail`,
          },
        ],
      },
    ],
  },
  {
    path: pathEnum.ContactUs,
    name: '联系我们',
    subheader: 'subheader',
    key: 'contact-us',
  },
  {
    path: '/search',
    name: 'search',
    hideInHeader: true,
    key: 'search',
  },
  {
    path: '/404',
    name: '',
    hideInHeader: true,
    key: '404',
  },
];

function processAllMenuMap(
  menuMap: Map<string, MenuItem[]>,
  menuList: MenuItem[],
  parantMenu?: MenuItem[],
) {
  for (const item of menuList) {
    const list: MenuItem[] = [...(parantMenu || []), item];
    item.sort = list.length;
    menuMap.set(item.key, list);
    if (item.children) {
      processAllMenuMap(menuMap, item.children, list);
    }
  }
  return menuMap;
}

export const allMenuMap = processAllMenuMap(new Map(), allMenuList);
const list = allMenuMap.values();
export const allPathList = [...list].map((item: MenuItem[]) => {
  const menu: MenuItem = item.reverse()[0] as MenuItem;
  return {
    ...menu,
    validatePath: (menu.path || '').split('?')[0],
  };
});

export function getBreadcrumbByPath(path: string, type: string) {
  const key = [...path.substring(1).split('/'), type].filter((item) => !!item).join('-') || 'index';
  const list = allMenuMap.get(key) || [];
  return list.sort((a: MenuItem, b: MenuItem) => (a.sort || 0) - (b.sort || 0));
}
