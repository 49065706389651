import React from 'react';
import { Space, Typography } from 'antd';
import { EnvironmentOutlined, PhoneOutlined } from '@ant-design/icons';
import { baseInfoType, linkType } from '@/utils/model';
import Image from 'next/image';
const { Text } = Typography;
const code1 = require(`@/assets/images/code-1.jpg`);
const code2 = require(`@/assets/images/code-2.jpg`);

const footerTextStyle: React.CSSProperties = {
  minWidth: '200px',
  width: 'calc(30vw - 300px)',
  maxWidth: '350px',
};

export default function DefaultFooter({ baseInfo }: { baseInfo: baseInfoType }) {
  const info = baseInfo?.contactInfo?.info || {};
  return (
    <div className="footer-wrapper">
      <div className="flex justify-center web-container">
        <div className="flex-1  margin-right-24">
          <Space direction="vertical" size={16} className="w-full">
            <h4 className="h6">友情链接</h4>
            <div className="flex flex-wrap align-top w-full">
              {(baseInfo?.metaInfo || []).map((linkList: linkType[], index: number) => (
                <div key={index} className="flex flex-col flex-1">
                  {linkList.map((item: linkType) => (
                    <a className="web-link" target="_blank" key={item.id} href={item.url}>
                      {item.title}
                    </a>
                  ))}
                </div>
              ))}
            </div>{' '}
          </Space>
        </div>
        <div className="flex flex-1 justify-between overflow-hidden">
          <Space className="flex-1 overflow-hidden" direction="vertical" size={16}>
            <h4 className="h6">联系方式</h4>
            <Space direction="vertical" size={8}>
              <div className="flex flex-wrap items-center footer-text">
                <Space>
                  <EnvironmentOutlined />{' '}
                  <Text className="footer-text color-white" style={footerTextStyle} ellipsis={true}>
                    {info.address}
                  </Text>
                </Space>
              </div>
              <div className="flex flex-wrap items-center footer-text">
                <Space>
                  <PhoneOutlined className="phone-icon" />{' '}
                  <Text className="footer-text color-white" style={footerTextStyle} ellipsis={true}>
                    {info.phones}
                  </Text>
                </Space>
              </div>
            </Space>
          </Space>
          <div className="flex">
            <div className="mx-4 flex-col items-center ">
              <div className="h-24 w-24 bg-white border-radius-10 overflow-hidden p-0.5">
                <Image src={code1} alt={''} />
              </div>
              <div className="text-center pt-1 footer-text">公众号</div>
            </div>
            <div>
              <div className="h-24 w-24 bg-white border-radius-10 overflow-hidden p-0.5">
                {' '}
                <Image src={code2} alt={''} />
              </div>
              <div className="text-center pt-1 footer-text">杂志</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-5 text-xs footer-bottom">
        <Space>
          <span>Copyright ©2007-2013 中国轮胎循环利用协会</span>
          <span>中国轮胎循环利用网  京ICP备13045182号-1</span>
        </Space>
      </div>
    </div>
  );
}
