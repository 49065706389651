/* eslint-disable no-unused-vars */
import { keyEnum } from './menu';
export enum pageSettingIdEnum {
  IndexPage = 1,
  AboutUs = 2,
  BookCoulum = 3,
}
export enum columnSettingIdEnum {
  defaultBook = 1,
}
export enum templateTypeEnum {
  SignleContent = 'single',
  MultipleContent = 'multiple',
  Book = 'publication',
  Person = 'leader',
}

export type SubHeaderItemType = {
  title: String;
  subTitle: String;
  top: number;
  width: number;
  height: number;
  imageKey: String;
};
export type SubHeaderInfoType = {
  key: String;
  count?: number;
  list: SubHeaderItemType[];
};
export const defaultSubHeaderInfo = {
  key: '',
  list: [],
};

export const subTitleList: SubHeaderInfoType[] = [
  {
    key: keyEnum.Association,
    list: [
      {
        title: '关于协会',
        subTitle:
          '中国轮胎循环利用协会（China Tyre Retreading and Recycling Association），简称“中轮协”（CTRA），国内惟一全国性废旧轮胎循环利用行业组织。',
        top: -13,
        width: 421,
        height: 180,
        imageKey: `${keyEnum.Association}-bg`,
      },
    ],
  },
  {
    key: keyEnum.Regulations,
    list: [
      {
        title: '法规行约',
        subTitle:
          '贯彻执行国家有关轮胎资源循环利用方面的方针、政策和法律法规，促进行业健康、有序地发展；加强行业自律，以行规行约规范会员行为，维护业内公平竞争，促进公开、公平、公正市场秩序的建立。',
        top: 29,
        width: 267,
        height: 180,
        imageKey: `${keyEnum.Regulations}-bg`,
      },
    ],
  },
  {
    key: `${keyEnum.MembersService}`,
    list: [
      {
        title: '商事调解',
        subTitle:
          '发挥行业调解优势，提供调解+司法确权化解各类纠纷的服务，健全诉调对接工作，推进行业内金融、商事领域纠纷多元化解机制，独立、公正、高效地帮助会员企业解决金融商事纠纷，促进行业企业健康发展，保护企业家的合法权益，提升营商环境。',
        top: 40,
        width: 431,
        height: 280,
        imageKey: `${keyEnum.MembersService}-1-bg`,
      },
      {
        title: '技术服务',
        subTitle:
          '协会汇聚了我国循环利用生态体系的专家和人士，依托协会技术委员会，为行业企业和有关机构积极开展各类技术专业服务，不断助推行业和企业科学技术进步和行业转型升级。',
        top: 66,
        width: 236,
        height: 280,
        imageKey: `${keyEnum.MembersService}-2-bg`,
      },
      {
        title: '法律服务',
        subTitle:
          '协会汇聚了法律届专家和有关人士等法律资源，可为会员企业提供法律事务咨询（免费）、诉讼服务等。',
        top: 66,
        width: 189,
        height: 280,
        imageKey: `${keyEnum.MembersService}-3-bg`,
      },
    ],
  },
  {
    key: keyEnum.Branch,
    list: [
      {
        title: '分支机构',
        subTitle:
          '协会分支机构包括：轮胎翻新分会、再生橡胶分会、橡胶粉及应用分会、轮胎热裂解分会、橡胶循环利用制品分会、行业发展委员会、技术委员会、金融商事调解中心。',
        top: 70,
        width: 361,
        height: 280,
        imageKey: `${keyEnum.Branch}-bg`,
      },
    ],
  },
  {
    key: keyEnum.StandardizationWork,
    list: [
      {
        title: '标准化工作',
        subTitle:
          '为使轮胎资源循环利用行业发展有章可循、生产过程规范可控、产品质量有统一的技术要求，为我国的环境保护、资源综合利用与节能减排工作做出贡献。建立健全科学合理、统一、权威的并与国际轮胎回收与循环利用标准体系接轨的专业领域标准体系，提高该领域的标准化水平。',
        top: 15,
        width: 374,
        height: 180,
        imageKey: `${keyEnum.StandardizationWork}-bg`,
      },
    ],
  },
  {
    key: keyEnum.PartyConstruction,
    list: [
      {
        title: '党建工作',
        subTitle:
          '坚持把党建工作摆在首位，加强功能型支部建设，发挥党建引领行业发展作用。把党建工作与业务开展高度融合起来，将最终成果落实到推动行业发展，提升行业水平上来。',
        top: 8,
        width: 411,
        height: 180,
        imageKey: `${keyEnum.PartyConstruction}-bg`,
      },
    ],
  },
  {
    key: keyEnum.ContactUs,
    list: [
      {
        title: '联系我们',
        subTitle: '您可通过以下方式与我们联系,我们将很高兴为您服务！',
        top: 8,
        width: 411,
        height: 180,
        imageKey: `${keyEnum.PartyConstruction}-bg`,
      },
    ],
  },
];

export const questionTypeList = [
  // {
  //   key: questionType.CommercialMediation,
  //   type: IndexTypeEnum.CommercialMediation,
  //   description: '商事调解',
  //   path: pathEnum.CommercialMediation,
  // },
  // {
  //   key: questionType.TechnicalProblem,
  //   type: IndexTypeEnum.TechnicalProblem,
  //   description: '技术服务',
  //   path: pathEnum.TechnicalProblem,
  // },
  // {
  //   key: questionType.LegalService,
  //   type: IndexTypeEnum.LegalService,
  //   description: '法律服务',
  //   path: pathEnum.LegalService,
  // },
];
