import React, { ReactElement, useEffect, useState } from 'react';
import { Layout } from 'antd';
import DefaultHeader from './components/header';
import DefaultFooter from './components/footer';
import { useRouter } from 'next/router';
import { MenuItem, allPathList } from '@/utils/menu';
import Head from 'next/head';
import { baseInfoType, columnIdType } from '@/utils/model';
const { Content } = Layout;

export default function DefaultLayout({
  children,
  menuList,
  baseInfo,
}: {
  children: ReactElement;
  menuList: MenuItem[];
  baseInfo: baseInfoType;
}) {
  const router = useRouter();
  const [current, setCurrent] = useState<columnIdType>('');
  const [title, setTitle] = useState<string>('');
  const [currentMenu, setCurrentMenu] = useState<MenuItem>();
  const getSelectedMenu = (id: columnIdType) => {
    console.log('🚀 ~ file: defaultLayout.tsx:28 ~ getSelectedMenu ~ menuList:', menuList, id);
    return menuList.find((item) => item.children?.find((child) => `${child.id}` === id));
  };
  useEffect(() => {
    if (!allPathList.find((item: MenuItem) => item.validatePath === router.pathname)) {
      router.replace('/404');
    }
    const id = (router.query.id || '') as columnIdType;
    const selectedMenu = getSelectedMenu(id);
    setCurrentMenu(selectedMenu);
    setTitle(selectedMenu?.title || selectedMenu?.name || '中国轮胎循环利用协会');
    setCurrent(id ? id : selectedMenu?.id);
  }, [menuList, router.asPath]);
  return (
    <Layout>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="renderer" content="webkit" />
        <meta name="baidu-site-verification" content="codeva-b0Zzm1E6Kh" />
        <meta name="force-rendering" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="IE=10,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="/logo.png" />
        <meta name="keywords" content="轮胎翻修,轮胎翻新,轮胎循环利用,再生胶,胶粉,热解" />
        <meta
          name="description"
          content="中国轮胎循环利用协会是1986年12月经原国家经济委员会批准、1987年4月在民政部登记注册，具有社团法人资格的国内惟一从事旧轮胎翻新再制造和废轮胎循环利用的全国性行业组织。协会为国务院国有资产监督管理委员会的代管协会，业务上接受国家发展和改革委员会、工业和信息化部、商务部的指导。"
        />
        {/* <link rel="shortcut icon" href="https://www.ctra.org.cn/favicon.ico"></link> */}
      </Head>
      <div className="main-container">
        <DefaultHeader menuList={menuList} current={current} />
        {/* <SubHeader currentMenu={currentMenu} breadcrumbList={Breadcrumb}></SubHeader> */}
        <Content className="flex-1 flex flex-col">{children}</Content>
        {currentMenu?.key !== '404' && <DefaultFooter baseInfo={baseInfo} />}
      </div>
    </Layout>
  );
}
