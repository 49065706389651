import React, { useState } from 'react';
import { Drawer, Input, Menu, MenuProps, Space } from 'antd';
import { MenuItem, allPathList } from '@/utils/menu';
import Router, { useRouter } from 'next/router';
import Image from 'next/image';
// import LogoSvg from '@/assets/images/logo-text.svg';
import SearchSvg from '@/assets/images/search.svg';
import { CloseOutlined } from '@ant-design/icons';
import { columnIdType } from '@/utils/model';
const logo = require('@/assets/images/logo.jpg');

const desfultStyle: React.CSSProperties = {
  boxShadow: '0px 1px 2px 0px rgba(218,218,218,0.5)',
};
const openStyle: React.CSSProperties = {
  borderBottom: '1px solid rgba(0,0,0,0.5)',
};

export default function DefaultHeader({
  menuList,
  current,
}: {
  menuList: MenuItem[];
  current: columnIdType;
}) {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setSearchValue('');
    setOpen(false);
  };
  const onChange = (e: { target: { value: string } }) => {
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    if (searchValue) {
      Router.push(`/search?keyword=${searchValue}`);
      onClose();
    }
  };

  const onClick: MenuProps['onClick'] = (e) => {
    const menu = allPathList.find((item: MenuItem) => item.key === e.key);
    console.log('🚀 ~ file: index.tsx:50 ~ menu:', menu);
    // setCurrent(e.id);
    // Router.push(menu?.path || '');
  };
  return (
    <div
      className="header-wrapper px-20 bg-white flex items-center justify-between sticky top-0 z-10 w-full"
      style={{ height: '60px', ...(open ? openStyle : desfultStyle) }}
    >
      <Space>
        <Image src={logo} height={38} alt="" />
      </Space>
      <div className="flex-1">
        <div className="m-auto">
          <Menu
            className="custom-menu"
            mode="horizontal"
            selectedKeys={[current as string]}
            items={menuList}
            onClick={onClick}
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode.parentNode.parentNode}
          />
        </div>
      </div>
      <div style={{ width: '60px' }}>
        {router.pathname !== '/search' && (
          <div className="header-search-icon" onClick={toggleDrawer}>
            <SearchSvg />
          </div>
        )}
      </div>
      <Drawer
        title="Drawer with extra actions"
        placement="top"
        rootClassName="search-drawer"
        height={156}
        maskClosable={false}
        onClose={onClose}
        open={open}
      >
        <div className="search-container">
          <Space.Compact className="search-wrapper">
            <Input value={searchValue} onChange={onChange} onPressEnter={onSearch} />
            <span className="search-icon" onClick={onSearch}>
              <SearchSvg />
            </span>
          </Space.Compact>
          <div onClick={onClose} className="search-close-icon">
            <CloseOutlined />
          </div>
        </div>
      </Drawer>
    </div>
  );
}
